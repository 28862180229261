<script setup lang="ts">
import { watch } from "vue";
import { onKeyStroke } from "@vueuse/core";
import { vOnClickOutside } from "@vueuse/components";
import { SidebarAutenticateHeader } from "./components/SidebarAutenticateHeader";
import { SidebarAutenticateForm } from "./components/SidebarAutenticateForm";
import { type SidebarAutenticateFormFormValues } from "./protocols";
import { useAppStore } from "@/store/app";
import { useAuthStore } from "@/store/auth";

const authStore = useAuthStore();

const store = useAppStore();

const mainErrorMessage = ref("");

function close() {
  store.setOpenSidebarAutenticate(false);
}

const redirectToInvestorArea = useRedirectToInvestorArea();

async function handleSubmit({
  nid,
  password,
}: SidebarAutenticateFormFormValues) {
  const httpResponse = await authStore.signIn({
    nid,
    password,
  });

  if (httpResponse) {
    redirectToInvestorArea();
  } else {
    mainErrorMessage.value = "Usuário ou senha inválidos.";
  }
}

watch(
  () => mainErrorMessage.value,
  () => {
    if (mainErrorMessage.value) {
      setTimeout(() => {
        mainErrorMessage.value = "";
      }, 2000);
    }
  },
);

watch(
  () => store.openSidebarAutenticate,
  () => {
    if (window) {
      if (store.openSidebarAutenticate) {
        window.document.body.classList.add("overflow-hidden");
      } else {
        window.document.body.classList.remove("overflow-hidden");
      }
    }
  },
);

onBeforeUnmount(() => {
  if (window) {
    window.document.body.classList.remove("overflow-hidden");
  }
});

onKeyStroke("Escape", (e) => {
  e.preventDefault();
  close();
});
</script>
<template>
  <div
    v-on-click-outside="close"
    data-testid="signin-sidebar"
    :class="`fixed h-full bg-white w-[360px] top-0 p-8 ease-in-out duration-300 z-50 ${
      store.openSidebarAutenticate ? 'right-0' : 'right-0 translate-x-[100%]'
    }`"
  >
    <div class="mb-8 z-50">
      <SidebarAutenticateHeader />
    </div>

    <SidebarAutenticateForm
      :loading="authStore.isLoading"
      @submit="handleSubmit"
    />
    <p v-show="mainErrorMessage" class="text-xs text-red-600 text-center mt-4">
      <span class="font-medium">{{ mainErrorMessage }}</span>
    </p>
  </div>
</template>
