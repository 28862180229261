<script setup lang="ts">
import AppLogo from "@/components/AppLogo.vue";
import AppNav from "@/components/AppNav.vue";
import AppMobileNav from "@/components/AppMobileNav.vue";
import { useAppStore } from "@/store/app";
import { useAuthStore } from "@/store/auth";

const appMobileNavIsOpen = ref(false);
const store = useAppStore();
const auth = useAuthStore();
const { $tt } = useNuxtApp();

const redirectToInvestorArea = useRedirectToInvestorArea();

const handleCloseMenu = () => {
  appMobileNavIsOpen.value = false;
};
</script>
<template>
  <header
    :style="{ position: appMobileNavIsOpen ? 'fixed' : 'relative' }"
    class="bg-dark-slate-gray text-white h-[84px] w-full z-10"
  >
    <div
      class="container h-full flex items-center justify-between start px-4 md:px-10 xl:px-20"
    >
      <div class="flex items-center flex-1 justify-start gap-28">
        <NuxtLink to="/" class="xl:hidden">
          <AppLogo size="short" />
        </NuxtLink>
        <NuxtLink to="/" class="hidden xl:block">
          <AppLogo />
        </NuxtLink>
        <AppNav />
      </div>
      <div class="font-poppins md:flex md:gap-x-4">
        <div class="hidden md:flex md:items-center md:gap-x-4">
          <template v-if="!auth.isAuthenticated">
            <button
              class="border rounded-lg border-white py-[10px] px-5 font-normal hover:bg-primary hover:text-black hover:border-primary"
              @click="() => store.setOpenSidebarAutenticate(true)"
            >
              {{ $tt("navbar.signin") }}
            </button>
            <button
              class="rounded-lg py-[10px] px-5 text-black bg-primary hover:bg-[#FFF] font-medium"
              @click="() => store.setOpenSidebarRegister(true)"
            >
              {{ $tt("navbar.signup") }}
            </button>
          </template>
          <template v-else>
            <button
              class="rounded-lg py-[10px] px-5 text-black bg-primary hover:bg-[#FFF] font-medium max-sm:hidden"
              @click="redirectToInvestorArea"
            >
              {{ $tt("navbar.investorArea") }}
            </button>
          </template>
        </div>
        <button
          class="cursor-pointer xl:hidden"
          aria-label="Menu"
          @click="() => (appMobileNavIsOpen = !appMobileNavIsOpen)"
        >
          <Icon name="ic:baseline-dehaze" color="#FFF" size="24" />
        </button>
        <AppMobileNav
          :open="appMobileNavIsOpen"
          @close-menu="handleCloseMenu"
        />
      </div>
    </div>
  </header>
</template>
