import { checkPhoneUsed } from "@/services/account";
import { PhoneAlreadyExistsError } from "~/composables/api/errors";

export const useCheckPhoneRepeated = () => {
  const { value: phone } = useField<string>("phone");
  const phoneRepeatedLoading = ref(false);
  const phoneRepeatedError = ref<string>();
  const { $tt } = useNuxtApp();

  const checkPhoneField = () => {
    if (phone.value.replace(/\D/g, "").length < 11) {
      phoneRepeatedError.value = undefined;
      return;
    }

    phoneRepeatedLoading.value = true;
    checkPhoneUsed({ phone: phone.value })
      .then(() => {
        phoneRepeatedError.value = undefined;
      })
      .catch((error) => {
        if (error instanceof PhoneAlreadyExistsError) {
          phoneRepeatedError.value = $tt("signup.phoneExistsError");
        }
      })
      .finally(() => {
        phoneRepeatedLoading.value = false;
      });
  };

  const debouncedCheckPhone = useDebounceFn(checkPhoneField, 1000);
  watch(phone, debouncedCheckPhone);

  return { phoneRepeatedError, phoneRepeatedLoading };
};
