import { object, string } from "yup";
import { naiveCheckCpfAndCnpj } from "@/utils/string/check-cpf-and-cnpj";

export const useLoginValidationSchema = () => {
  const { $tt } = useNuxtApp();

  const requiredMessage = $tt("shared.requiredField");

  return object({
    nid: string()
      .test("check-cpf-and-cnpj", $tt("shared.nidInvalid"), (value) =>
        naiveCheckCpfAndCnpj(value as string),
      )
      .required(requiredMessage)
      .trim(),
    password: string().required(requiredMessage).trim(),
  });
};
