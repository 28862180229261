import { checkNid } from "@/services/account";
import { NidAlreadyExistsError } from "~/composables/api/errors";

export const useCheckNidRepeated = () => {
  const { value: nid } = useField<string>("cpfOrCnpj");
  const nidRepeatedLoading = ref(false);
  const nidRepeatedError = ref<string>();
  const { $tt } = useNuxtApp();

  const checkNidField = () => {
    if (nid.value.replace(/\D/g, "").length < 11) {
      nidRepeatedError.value = undefined;
      return;
    }

    nidRepeatedLoading.value = true;
    checkNid({ nid: nid.value })
      .then(() => {
        nidRepeatedError.value = undefined;
      })
      .catch((error) => {
        if (error instanceof NidAlreadyExistsError) {
          nidRepeatedError.value = $tt("signup.nidExistsError");
        }
      })
      .finally(() => {
        nidRepeatedLoading.value = false;
      });
  };

  const debouncedCheckNid = useDebounceFn(checkNidField, 1000);
  watch(nid, debouncedCheckNid);

  return { nidRepeatedError, nidRepeatedLoading };
};
