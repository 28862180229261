<script setup lang="ts">
import { type CheckboxProps } from "./protocols";

const props = defineProps<CheckboxProps>();
const { value, errorMessage } = useField(() => props.name);
</script>
<template>
  <div class="flex items-start justify-center flex-col">
    <div class="flex items-center">
      <input
        :id="props.name"
        v-model="value"
        :name="name"
        type="checkbox"
        class="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:primary-600 focus:ring-2 accent-primary-600"
      />
      <slot name="label"></slot>
    </div>
    <p v-show="errorMessage" class="mt-1 text-xs text-red-600">
      <span class="font-medium">{{ errorMessage }}</span>
    </p>
  </div>
</template>
