<script lang="ts" setup>
const { $tt } = useNuxtApp();
</script>

<template>
  <nav class="hidden xl:block">
    <ul class="flex gap-x-[30px] font-poppins">
      <li class="group relative cursor-pointer text-base font-medium">
        <span class="flex items-center gap-x-[8px] hover:text-primary-600">
          {{ $tt("navbar.about") }}
          <Icon name="ic:round-keyboard-arrow-down" size="20" />
        </span>
        <div
          class="absolute z-0 bg-black rounded-lg min-w-[calc(100%+16px)] invisible group-hover:visible"
        >
          <ul class="py-2">
            <li>
              <NuxtLink
                to="/quem-somos"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                {{ $tt("navbar.whoWeAre") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/cases-de-sucesso"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                {{ $tt("navbar.cases") }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/quem-somos/#trabalhe-conosco"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                {{ $tt("navbar.workWithUs") }}
              </NuxtLink>

              <NuxtLink
                to="https://blog.hurst.capital"
                class="flex items-center gap-x-[8px] p-3 font-nunito font-semibold whitespace-nowrap hover:bg-primary-600 px-4"
              >
                {{ $tt("navbar.blog") }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </li>
      <li class="text-base font-semibold">
        <NuxtLink
          to="/oportunidades"
          class="flex items-center gap-x-[8px] font-medium hover:text-primary-600"
        >
          {{ $tt("navbar.opportunities") }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>
