<script setup lang="ts">
export interface AppLogoProps {
  size?: "short" | "long";
}
const props = withDefaults(defineProps<AppLogoProps>(), {
  size: "long",
});
</script>

<template>
  <img
    v-if="props.size === 'long'"
    src="/long-hurst-logo.svg"
    alt="Logo da Hurst Capital"
    width="160"
    height="38"
    loading="lazy"
  />
  <img
    v-if="props.size === 'short'"
    src="/short-logo-hurst.svg"
    alt="Logo da Hurst Capital"
    loading="lazy"
    width="26"
    height="30"
  />
</template>
