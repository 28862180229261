<script setup lang="ts">
import type { SignUpRequest } from "../../../protocols";
import { useCheckEmailRepeated } from "./composables/useCheckEmailRepeated";
import { useCheckNidRepeated } from "./composables/useCheckNidRepeated";
import { useCheckPhoneRepeated } from "./composables/useCheckPhoneRepeated";
import {
  type SignUpStepFormProps,
  type SignUpStepFormValues,
} from "./protocols";
import { makeNid, makePhone, usePartner } from "./utils";
import { useSignupValidationSchema } from "./validation";
import { FloatingLabelInput } from "@/components/forms/FloatingLabelInput";
import { Checkbox } from "@/components/forms/Checkbox";
import { Button } from "@/components/Button";

const { $tt } = useNuxtApp();

const props = defineProps<SignUpStepFormProps>();

const emit = defineEmits<{
  (e: "submit", signUpRequest: SignUpRequest): void;
}>();

const validationSchema = useSignupValidationSchema();
const { handleSubmit } = useForm<SignUpStepFormValues>({
  validationSchema,
});

const { emailRepeatedError, emailRepeatedLoading } = useCheckEmailRepeated();
const { phoneRepeatedError, phoneRepeatedLoading } = useCheckPhoneRepeated();
const { nidRepeatedError, nidRepeatedLoading } = useCheckNidRepeated();

const onSubmit = handleSubmit((signUpStepFormValues) => {
  if (
    emailRepeatedLoading.value ||
    phoneRepeatedLoading.value ||
    nidRepeatedLoading.value ||
    emailRepeatedError.value ||
    phoneRepeatedError.value ||
    nidRepeatedError.value
  ) {
    return;
  }

  const { fullName, cpfOrCnpj, phone, email, password } = signUpStepFormValues;

  const payload: SignUpRequest = {
    fullName,
    nid: makeNid(cpfOrCnpj),
    phone: makePhone(phone),
    email,
    password,
    partner: usePartner(),
  };

  emit("submit", payload);
});
</script>

<template>
  <form class="flex flex-col z-50" autocomplete="off" @submit="onSubmit">
    <div class="flex flex-col gap-y-5">
      <FloatingLabelInput
        :label="$tt('signup.name')"
        name="fullName"
        :aria-label="$tt('signup.name')"
      />
      <FloatingLabelInput
        v-maska
        :label="$tt('signup.nid')"
        :aria-label="$tt('signup.nid')"
        name="cpfOrCnpj"
        data-maska="###.###.###-##"
        :loading="nidRepeatedLoading"
        :custom-error="nidRepeatedError"
      />
      <FloatingLabelInput
        :label="$tt('signup.phone')"
        :aria-label="$tt('signup.phone')"
        name="phone"
        :loading="phoneRepeatedLoading"
        :custom-error="phoneRepeatedError"
        input-type="phone"
      />
      <FloatingLabelInput
        :label="$tt('signup.email')"
        name="email"
        :loading="emailRepeatedLoading"
        :custom-error="emailRepeatedError"
      />
      <FloatingLabelInput
        :label="$tt('signup.password')"
        autocomplete="new-password"
        :aria-label="$tt('signup.password')"
        name="password"
        type="password"
      />
      <FloatingLabelInput
        :label="$tt('signup.passwordConfirmation')"
        autocomplete="new-password"
        :aria-label="$tt('signup.passwordConfirmation')"
        name="passwordConfirmation"
        type="password"
      />
      <Checkbox name="terms">
        <template #label>
          <i18n-t
            keypath="signup.agreed"
            tag="label"
            class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            <template #terms>
              <NuxtLink to="/termos-de-uso" class="underline">
                {{ $tt("signup.terms") }}
              </NuxtLink>
            </template>
            <template #privacy>
              <NuxtLink to="/politica-de-privacidade" class="underline">
                {{ $tt("signup.privacy") }}
              </NuxtLink>
            </template>
          </i18n-t>
        </template>
      </Checkbox>
      <Button :loading="props.loading">
        {{ $tt("signup.submit") }}
      </Button>
    </div>
  </form>
</template>
