import { extractNumbers } from "@/utils/string/extract-numbers";
import { formatPhoneNumber } from "@/utils/string/format-phone-number";

export const makeDevice = () => {
  const device = navigator.userAgent;
  return device;
};

export const makeLanguageId = () => {
  return "br";
};

export const makeNickName = (fullName: string) => {
  return fullName;
};

export const makeSource = () => {
  return "Plataforma Hurst";
};

export const makeNid = (nid: string) => {
  return extractNumbers(nid);
};

export const makePhone = (phone: string) => {
  return formatPhoneNumber(phone);
};

export const usePartner = (): string | null => {
  const route = useRoute();
  const partner = route.query.partner;

  if (typeof partner === "string") {
    return partner;
  } else {
    return null;
  }
};
