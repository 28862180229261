<script setup lang="ts">
import { useField } from "vee-validate";
import { type FloatingLabelInputProps } from "./protocols";
import GenericInput from "@/components/CustomInputs/GenericInput.vue";

const props = defineProps<FloatingLabelInputProps>();

const { value, errorMessage } = useField<string>(() => props.name);

const fieldId = computed(() => props.id ?? `input-${props.name}`);
</script>

<template>
  <div>
    <div class="relative z-0">
      <GenericInput
        :id="fieldId"
        v-model="value"
        :name="name"
        type="text"
        :data-error="Boolean(errorMessage)"
        :class="`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary hover:border-[#667382] peer   data-[error=true]:focus:border-red-600 data-[error=true]:hover:border-red-600 data-[error=true]:border-red-600`"
        placeholder=" "
        autocomplete="off"
        v-bind="$attrs"
        :input-type="props.inputType"
      />
      <label
        :for="fieldId"
        :data-error="Boolean(errorMessage)"
        :class="`absolute text-sm text-gray-500 data-[error=true]:text-red-600 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-border-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6`"
      >
        {{ props.label }}
      </label>
      <div v-if="$props.loading" class="input-loading">
        <Icon name="mdi:loading" class="animate-spin" size="24" />
      </div>
    </div>
    <p
      v-show="errorMessage || $props.customError"
      class="mt-1 text-xs text-red-600"
    >
      <span class="font-medium">{{ $props.customError || errorMessage }}</span>
    </p>
  </div>
</template>

<style scoped>
.input-loading {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  position: absolute;
}
</style>
