import { object, string, ref as yupRef } from "yup";
import { naiveCheckCpfAndCnpj } from "@/utils/string/check-cpf-and-cnpj";

export const useForgotPasswordConfirmValidationSchema = () => {
  const { $tt } = useNuxtApp();

  const requiredMessage = $tt("shared.requiredField");

  return object({
    nid: string()
      .test("check-cpf-and-cnpj", $tt("shared.nidInvalid"), (value) =>
        naiveCheckCpfAndCnpj(value as string),
      )
      .required(requiredMessage)
      .trim(),
    password: string()
      .required(requiredMessage)
      .min(8, $tt("shared.password.minLength", 8))
      .matches(/[0-9]/, $tt("shared.password.mustContainNumber"))
      .matches(/[a-z]/, $tt("shared.password.mustContainLower"))
      .matches(/[A-Z]/, $tt("shared.password.mustContainUpper")),
    passwordConfirmation: string()
      .required(requiredMessage)
      .oneOf([yupRef("password")], $tt("shared.password.notMatch")),
    code: string().required(requiredMessage),
  });
};
