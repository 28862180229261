import { waitForInteraction } from "./wait-for-interaction";

const useInjectScripts = () => {
  const env = useRuntimeConfig();

  return () => {
    if (typeof window === "undefined" || env.public.stage !== "prod") return;

    const hubspotScript = document.createElement("script");
    hubspotScript.setAttribute("defer", "true");
    hubspotScript.src = "https://js.hs-scripts.com/8675958.js";
    // hubspotScript.setAttribute("type", "text/partytown");
    hubspotScript.setAttribute("async", "true");

    const tagManagerScript = document.createElement("script");
    tagManagerScript.setAttribute("defer", "true");
    // tagManagerScript.setAttribute("type", "text/partytown");
    tagManagerScript.setAttribute("async", "true");
    tagManagerScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${env.public.googleTagManagerId}');
    `;

    const gtagScript = document.createElement("script");
    gtagScript.setAttribute("defer", "true");
    // gtagScript.setAttribute("type", "text/partytown");
    gtagScript.setAttribute("async", "true");
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${env.public.googleAnalyticsId}`;

    const analyticsScript = document.createElement("script");
    analyticsScript.setAttribute("defer", "true");
    // analyticsScript.setAttribute("type", "text/partytown");
    analyticsScript.setAttribute("async", "true");
    analyticsScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${env.public.googleAnalyticsId}', {
        page_path: window.location.pathname,
      });
    `;

    document.head.appendChild(hubspotScript);
    document.head.appendChild(tagManagerScript);
    document.head.appendChild(gtagScript);
    document.head.appendChild(analyticsScript);
  };
};

export const setup3rdPartyScripts = async () => {
  const injectScripts = useInjectScripts();

  try {
    await waitForInteraction();
    injectScripts();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error initializing Google Analytics:");
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
