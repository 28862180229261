<script setup lang="ts">
import { type SidebarAutenticateFormFormValues } from "../../protocols";
import type { SidebarAutenticateFormProps } from "./protocols";
import { useLoginValidationSchema } from "./validation";
import { Button } from "@/components/Button";
import { FloatingLabelInput } from "@/components/forms/FloatingLabelInput";
import { useAppStore } from "@/store/app";
import { extractNumbers } from "@/utils/string/extract-numbers";

const props = defineProps<SidebarAutenticateFormProps>();

const emit = defineEmits<{
  (
    e: "submit",
    sidebarAutenticateFormFormValues: SidebarAutenticateFormFormValues,
  ): void;
}>();

const { $tt } = useNuxtApp();

const validationSchema = useLoginValidationSchema();

const store = useAppStore();

const { handleSubmit } = useForm<SidebarAutenticateFormFormValues>({
  validationSchema,
});

const onSubmit = handleSubmit((sidebarAutenticateFormValues) => {
  const { nid, password } = sidebarAutenticateFormValues;
  emit("submit", { nid: extractNumbers(nid), password });
});

function openForgotPasswordDrawer() {
  store.setOpenSidebarAutenticate(false);
  store.setOpenForgotPasswordDrawer(true);
}
</script>
<template>
  <form
    class="flex flex-col gap-y-5 z-50"
    autocomplete="off"
    @submit="onSubmit"
  >
    <FloatingLabelInput
      v-maska
      name="nid"
      :aria-label="$tt('shared.nidLabel')"
      :label="$tt('shared.nidLabel')"
      data-maska="[
    '###.###.###-##',
    '##.###.###/####-##'
  ]"
    />
    <FloatingLabelInput
      type="password"
      autocomplete="current-password"
      name="password"
      :aria-label="$tt('login.passwordLabel')"
      :label="$tt('login.passwordLabel')"
    />
    <Button :loading="props.loading">
      {{ $tt("login.submit") }}
    </Button>

    <button
      type="button"
      class="font-nunito text-sm font-normal text-center cursor-pointer hover:underline"
      @click="openForgotPasswordDrawer"
    >
      {{ $tt("login.forgotPassword") }}
    </button>
  </form>
</template>
