<script setup lang="ts">
import {
  sidebarRegisterProvider,
  type SidebarRegisterProvider,
} from "../../../protocols";
import { Otp } from "@/components/forms/Otp";
import { useConfirmRegister } from "~/composables/api/auth/useConfirmRegister";
import { useConfirmRegisterResendEmail } from "~/composables/api/auth/useConfirmRegisterResendEmail";

const emit = defineEmits(["code-confirmation", "resend-by-email"]);

const { signUpRequest } = inject<SidebarRegisterProvider>(
  sidebarRegisterProvider,
) as SidebarRegisterProvider;

const { $tt } = useNuxtApp();

const otpErrorMessage = ref("");

const confirmRegisterResendEmail = useConfirmRegisterResendEmail();
const confirmRegister = useConfirmRegister();

async function handleOtpSubmit(code: string) {
  try {
    await confirmRegister.mutateAsync({
      code,
      nid: signUpRequest!.value?.nid ?? "",
    });
    emit("code-confirmation", true);
  } catch (error: any) {
    otpErrorMessage.value = error.message;
  }
}

async function handleRsendCodeByEmail() {
  try {
    await confirmRegisterResendEmail.mutateAsync({
      nid: signUpRequest.value?.nid ?? "",
    });

    emit("resend-by-email");
  } catch (error) {
    if (error instanceof Error) {
      otpErrorMessage.value = error.message;
    }
  }
}

function handleOtpChange() {
  if (otpErrorMessage.value) otpErrorMessage.value = "";
}
</script>
<template>
  <form>
    <Otp
      :size="6"
      :errormessage="otpErrorMessage"
      @submit="handleOtpSubmit"
      @change="handleOtpChange"
    />
    <div class="mt-5 flex flex-col gap-y-3">
      <button
        type="button"
        class="font-nunito text-sm font-normal text-center cursor-pointer hover:underline"
        :arial-label="$tt('signup.emailResend')"
        @click.prevent="handleRsendCodeByEmail"
      >
        {{ $tt("signup.emailResend") }}
      </button>
    </div>
  </form>
</template>
