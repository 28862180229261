import { toTypedSchema } from "@vee-validate/yup";
import { boolean, object, string, ref as yupRef } from "yup";
import { validateCpf } from "@/utils/string/check-cpf-and-cnpj";
import { isPhoneValid } from "@/utils/string/is-phone-valid";

export const useSignupValidationSchema = () => {
  const { $tt } = useNuxtApp();
  const requiredMessage = $tt("shared.requiredField");

  return toTypedSchema(
    object({
      fullName: string().required(requiredMessage).trim(),
      cpfOrCnpj: string()
        .test("check-cpf-and-cnpj", $tt("signup.nidInvalid"), (value) =>
          validateCpf(value as string),
        )
        .required(requiredMessage),
      phone: string()
        .test("is-phone-valid", $tt("signup.phoneInvalid"), (value) => {
          return isPhoneValid(value);
        })
        .required(requiredMessage)
        .trim()
        .default("55"),
      email: string()
        .email($tt("signup.emailInvalid"))
        .required(requiredMessage)
        .trim(),
      password: string()
        .required(requiredMessage)
        .min(8, $tt("shared.password.minLength", 8))
        .matches(/[0-9]/, $tt("shared.password.mustContainNumber"))
        .matches(/[a-z]/, $tt("shared.password.mustContainLower"))
        .matches(/[A-Z]/, $tt("shared.password.mustContainUpper")),
      passwordConfirmation: string()
        .required(requiredMessage)
        .oneOf([yupRef("password")], $tt("shared.password.notMatch")),
      terms: boolean().required(requiredMessage),
    }),
  );
};
