<script setup lang="ts">
import { firebaseAnalytics } from "../infra/firebase/firebaseAnalytics/firebase-analytics-helper";
import AppHeader from "@/components/AppHeader.vue";
import { SidebarRegister } from "@/components/SidebarRegister";
import { SidebarAutenticate } from "@/components/SidebarAutenticate";
import { ForgotPasswordDrawer } from "@/components/ForgotPasswordDrawer";
import { useAppStore } from "~/store/app";
import { setup3rdPartyScripts } from "~/utils/internet/setup-3rd-party-scripts";

useLandingHead();

const env = useRuntimeConfig();
const appStore = useAppStore();

onMounted(() => {
  if (process.client && env.public.stage === "prod") {
    firebaseAnalytics();
    setup3rdPartyScripts();
  }

  if (process.client) {
    document.addEventListener("click", (event) => {
      const target = event.target as HTMLElement;
      if (typeof target.dataset.onclick === "string") {
        event.preventDefault();
      }

      if (target.dataset.onclick === "signup") {
        appStore.setOpenSidebarRegister(true);
      }
    });
  }
});
</script>
<template>
  <div>
    <AppHeader />
    <main>
      <suspense>
        <slot></slot>
      </suspense>
    </main>
    <ForgotPasswordDrawer />
    <SidebarAutenticate />
    <SidebarRegister />
    <NuxtIsland name="AppFooter" />
  </div>
</template>
