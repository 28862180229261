const events: (keyof WindowEventMap)[] = [
  "click",
  "keydown",
  "touchstart",
  "scroll",
  "mousemove",
];

export const waitForInteraction = (): Promise<void> => {
  const interactionPromise = new Promise<void>((resolve) => {
    events.forEach((event) => {
      window.addEventListener(event, () => resolve(), { once: true });
    });
  });

  const timeoutPromise = new Promise<void>((resolve) => {
    setTimeout(() => resolve(), 5000);
  });

  return Promise.race([interactionPromise, timeoutPromise]);
};
