<script setup lang="ts">
import { inject, ref, watch } from "vue";
import {
  sidebarRegisterProvider,
  type SidebarRegisterProvider,
  type SignUpRequest,
} from "../protocols";
import { SignUpStepForm } from "./components/SignUpStepForm";
import { SignUpStepHeader } from "./components/SignUpStepHeader";
import { useRegister } from "~/composables/api/auth/useRegister";

const mainErrorMessage = ref("");
const loading = ref(false);
const { setStep, setSignUpRequest } = inject<SidebarRegisterProvider>(
  sidebarRegisterProvider,
) as SidebarRegisterProvider;

const register = useRegister();

async function handleSubmit(signUpData: SignUpRequest) {
  try {
    loading.value = true;
    const { accountNotConfirmed } = await register.mutateAsync({
      email: signUpData.email,
      fullName: signUpData.fullName,
      nid: signUpData.nid,
      phone: signUpData.phone,
      password: signUpData.password,
      partner: signUpData.partner,
    });
    setSignUpRequest({
      ...signUpData,
      codeSentTo: accountNotConfirmed ? "email" : "phone",
    });
    setStep("ConfirmCodeStep");
  } catch (error: any) {
    mainErrorMessage.value = error.message;
  } finally {
    loading.value = false;
  }
}

watch(
  () => mainErrorMessage.value,
  () => {
    if (mainErrorMessage.value) {
      setTimeout(() => {
        mainErrorMessage.value = "";
      }, 2000);
    }
  },
);
</script>
<template>
  <div class="mb-8 z-50">
    <SignUpStepHeader />
  </div>
  <SignUpStepForm :loading="loading" @submit="handleSubmit" />
  <p
    v-show="mainErrorMessage"
    class="text-xs text-red-600 text-center mt-4 z-50"
  >
    <span class="font-medium">{{ mainErrorMessage }}</span>
  </p>
</template>
