<script setup lang="ts">
import {
  forgotPasswordDrawerProvider,
  type ConfirmationCodeForgetPasswordRequest,
  type ForgotPasswordDrawerProvider,
} from "../../../protocols";
import { useForgotPasswordConfirmValidationSchema } from "./validation";
import { Button } from "@/components/Button";
import { FloatingLabelInput } from "@/components/forms/FloatingLabelInput";
import { forgotPasswordResendCodeEmail } from "@/services/account";
import { extractNumbers } from "@/utils/string/extract-numbers";

const emit = defineEmits<{
  (
    e: "submit",
    forgotPasswordDrawerFormValues: ConfirmationCodeForgetPasswordRequest,
  ): void;
}>();

const { $tt } = useNuxtApp();

const validationSchema = useForgotPasswordConfirmValidationSchema();
const { handleSubmit, setValues } =
  useForm<ConfirmationCodeForgetPasswordRequest>({
    validationSchema,
  });

const { value: nidField } = useField<string>("nid");

const { confirmationCodeForgetPasswordRequest, reset } =
  inject<ForgotPasswordDrawerProvider>(
    forgotPasswordDrawerProvider,
  ) as ForgotPasswordDrawerProvider;

const { countdownCounter, countdownOver, restartCountdown } = useCountdown({
  startWith: 0,
});

const sendCodeViaEmail = async () => {
  const notify = useNotify();
  if (!countdownOver.value) return;

  restartCountdown();

  try {
    await forgotPasswordResendCodeEmail({
      platform: "Plataforma Hurst",
      nid: nidField.value?.replace?.(/\D/g, "") ?? "",
      device: navigator.userAgent,
    });
    notify($tt("forgotPassword.codeSent"), { type: "success" });
  } catch (e) {
    notify($tt("forgotPassword.codeSentError"), { type: "error" });
  }
};

const onSubmit = handleSubmit((confirmationCodeForgetPasswordRequest) => {
  const { nid, ...rest } = confirmationCodeForgetPasswordRequest;
  delete rest.passwordConfirmation;
  emit("submit", { nid: extractNumbers(nid), ...rest });
});

onMounted(() => {
  if (!confirmationCodeForgetPasswordRequest.value) {
    reset();
  }
  setValues({
    nid: confirmationCodeForgetPasswordRequest.value!.nid as string,
  });
});
</script>
<template>
  <form class="flex flex-col gap-y-5" autocomplete="off" @submit="onSubmit">
    <FloatingLabelInput
      v-maska
      name="nid"
      :label="$tt('shared.nidLabel')"
      :aria-label="$tt('shared.nidLabel')"
      data-maska="[
    '###.###.###-##',
    '##.###.###/####-##'
  ]"
    />
    <FloatingLabelInput
      name="code"
      :aria-label="$tt('forgotPassword.code')"
      :label="$tt('forgotPassword.code')"
    />
    <FloatingLabelInput
      name="password"
      :label="$tt('forgotPassword.newPassword')"
      :aria-label="$tt('forgotPassword.newPassword')"
      type="password"
    />
    <FloatingLabelInput
      name="passwordConfirmation"
      :label="$tt('forgotPassword.passwordConfirmation')"
      :aria-label="$tt('forgotPassword.passwordConfirmation')"
      type="password"
    />

    <button
      v-if="countdownOver"
      type="button"
      class="underline"
      @click="sendCodeViaEmail()"
    >
      {{ $tt("forgotPassword.sendCodeEmail") }}
    </button>
    <p v-else class="text-center">
      {{ $tt("forgotPassword.sendCodeEmail") }} ({{ countdownCounter }})
    </p>

    <Button>
      {{ $tt("forgotPassword.sendCode") }}
    </Button>
  </form>
</template>
