import { checkEmail } from "@/services/account";
import { EmailAlreadyExistsError } from "~/composables/api/errors";

export const useCheckEmailRepeated = () => {
  const { value: email } = useField<string>("email");
  const emailRepeatedLoading = ref(false);
  const emailRepeatedError = ref<string>();
  const { $tt } = useNuxtApp();

  const checkEmailField = () => {
    emailRepeatedLoading.value = true;
    checkEmail({ email: email.value })
      .then(() => {
        emailRepeatedError.value = undefined;
      })
      .catch((error) => {
        if (error instanceof EmailAlreadyExistsError) {
          emailRepeatedError.value = $tt("signup.emailExistsError");
        }
      })
      .finally(() => {
        emailRepeatedLoading.value = false;
      });
  };

  const debouncedCheckEmail = useDebounceFn(checkEmailField, 1000);
  watch(email, debouncedCheckEmail);

  return { emailRepeatedError, emailRepeatedLoading };
};
