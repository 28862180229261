import { useNuxtApp } from "nuxt/app";
import type {
  ConfirmationCodeForgetPasswordRequest,
  ForgotPasswordDrawerFormRequest,
} from "@/components/ForgotPasswordDrawer/protocols";
import type { ResendCodeRequest } from "@/components/SidebarRegister/protocols";
import { getDomainError } from "~/composables/api/errors";

export async function getAccountById(accountId: string) {
  const { $api } = useNuxtApp();
  try {
    const httpResponse = await $api(`/account/${accountId}`);
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function forgotPassword(
  forgotPasswordRequest: ForgotPasswordDrawerFormRequest,
) {
  const { $api } = useNuxtApp();

  try {
    const httpResponse = await $api<any>(`/account/notauth/forgot`, {
      method: "POST",
      body: forgotPasswordRequest,
    });
    return httpResponse.data;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function forgotPasswordResendCodeEmail(payload: {
  nid: string;
  platform: string;
  device: string;
}) {
  const { $api } = useNuxtApp();
  try {
    const httpResponse = await $api("/account/notauth/forgot/email", {
      method: "POST",
      body: payload,
    });
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function confirmForgotPassword(
  confirmationCodeForgetPasswordRequest: ConfirmationCodeForgetPasswordRequest,
) {
  const { $api } = useNuxtApp();
  try {
    const httpResponse = await $api(
      `/account/notauth/confirm-password?md5=true`,
      {
        method: "POST",
        body: confirmationCodeForgetPasswordRequest,
      },
    );
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function resendCodeByEmail(payload: ResendCodeRequest) {
  const { $api } = useNuxtApp();
  try {
    const httpResponse = await $api("account/notauth/resend/email", {
      method: "POST",
      body: payload,
    });
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function getConstants() {
  const { $api } = useNuxtApp();
  try {
    const httpResponse = await $api<any>(`/account/notauth/constants`);
    return httpResponse;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.data?.message || "Ocorreu um erro";
    throw new Error(errorMessage);
  }
}

export async function checkEmail({ email }: { email: string }) {
  const env = useRuntimeConfig();
  try {
    return await $fetch("/v2/account/v3/notauth/check-email", {
      method: "GET",
      baseURL: env.public.apiRootURL,
      params: { email },
    });
  } catch (error) {
    throw getDomainError(error);
  }
}

export async function checkPhoneUsed({ phone }: { phone: string }) {
  const env = useRuntimeConfig();

  try {
    return await $fetch("/v2/account/v3/notauth/check-phone", {
      method: "GET",
      baseURL: env.public.apiRootURL,
      params: { phone: phone.replace(/\D/g, "") },
    });
  } catch (error) {
    throw getDomainError(error);
  }
}

export async function checkNid({ nid }: { nid: string }) {
  const env = useRuntimeConfig();

  try {
    return await $fetch("/v2/account/v3/notauth/check-nid", {
      method: "GET",
      baseURL: env.public.apiRootURL,
      params: { nid: nid.replace(/\D/g, "") },
    });
  } catch (error) {
    throw getDomainError(error);
  }
}
