<script setup lang="ts">
import { provide, ref } from "vue";
import SidebarRegisterRoot from "./SidebarRegisterRoot.vue";
import {
  type SidebarRegisterProvider,
  type SignUpRequest,
  sidebarRegisterProvider,
} from "./protocols";
import { SignUpStep } from "./SignUpStep";
import { ConfirmCodeStep } from "./ConfirmCodeStep";

const steps = {
  SignUpStep,
  ConfirmCodeStep,
};
const openSidebarRegister = ref(false);
const currentStep = shallowRef(steps.SignUpStep);
const signUpRequest = shallowRef<SignUpRequest | null>(null);

function setStep(step: keyof typeof steps) {
  currentStep.value = steps[step];
}

function setSignUpRequest(signUpData: SignUpRequest | null) {
  signUpRequest.value = signUpData;
}

provide<SidebarRegisterProvider>(sidebarRegisterProvider, {
  signUpRequest,
  openSidebarRegister,
  currentStep,
  setStep,
  setSignUpRequest,
});
</script>
<template>
  <SidebarRegisterRoot />
</template>
