import { waitForInteraction } from "~/utils/internet/wait-for-interaction";

const setupFirebaseAnalytics = async () => {
  const { getApp, getApps, initializeApp } = await import("firebase/app");
  const { initializeAnalytics } = await import("firebase/analytics");

  const apps = getApps();

  const firebaseConfigKeys = {
    apiKey: "AIzaSyABpSfgTnDyRaKn_E_Fxrdh2qRCnOxi51g",
    authDomain: "hurst-capital.firebaseapp.com",
    databaseURL: "https://hurst-capital.firebaseio.com",
    projectId: "hurst-capital",
    appId: "1:910151733635:web:793e453b465b705a3366b6",
    storageBucket: "hurst-capital.appspot.com",
    messagingSenderId: "910151733635",
    measurementId: "G-4BNW166NT2",
  };

  if (!apps.length) {
    initializeApp(firebaseConfigKeys);
  }

  const app = getApp();

  if (app.name && typeof window !== "undefined") {
    initializeAnalytics(app);
  }
};

export const firebaseAnalytics = async () => {
  try {
    await waitForInteraction();
    await setupFirebaseAnalytics();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error initializing Firebase Analytics:");
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
